body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.categories_section {
  margin: 100px;
}

.categories_section ul {
  font-size: 18px;
  list-style: none;
  cursor: pointer;
}

.categories_section input {
  font-size: 16px;
  padding: 8px 15px;
  width: 200px;
  outline: none;
  background: #ffffff;
  color: #000000;
  border: 1px solid #c4d1eb;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 0px #e2e2e2;
  transition: 0.3s ease;
}

.categories_section button {
  width: 100px;
  margin-left: 20px;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.confirm {
  background-color: #04AA6D;
  color: white;
  border-color: lightgrey;
}
